import React from "react";
import "./Biometric.css";
import biohrm from "../Products/Products/biometriccrm1.jpg";
import Hrm from "../Products/img/biom1.jpg";
import bio_metric from "../Products/img/bio2.jpeg";
import { Helmet } from "react-helmet-async";

const BiometricPage = () => {
  return (
    <>
      <Helmet>
        <title>Biometric-based HRM software provider in Coimbtaore</title>

        <meta
          name="description"
          content=" Kitkat software technology is the best biometric-based HRM provider in 
Coimbatore, with over 10 years of excellent services to our clients. "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={biohrm}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              Biometric Based HRM Software from KitKat Software Technologies
              makes HR management simpler by securely tracking employee
              attendance using unique physical traits, like fingerprints. This
              reduces manual work, prevents buddy punching, and enhances
              punctuality, making HR operations more efficient and transparent
            </p>
            <p className="cont_para_style">
              Our biometric automates the system & helps HR managers to save
              time, improve employee satisfaction, and maintain a secure work
              environment. Additionally, allowing HR teams to monitor workforce
              data from anywhere.
            </p>

            <div className="img_pro">
              <img
                src={Hrm}
                width="100%"
                height="100%"
                alt="Hrm Management"
                style={{ objectFit: "cover" }}
              />
            </div>

            {/* Frequently Asked Questions Section */}
            <div>
              <p className="cont_para_style">
                Our developers designed this biometric Based HRM to cater to
                businesses of all sizes, from startups to large enterprises,
                ensuring a user-friendly experience. We will customize the
                software based on your business needs to enhance usability and
                efficiency.
              </p>
            </div>
            <div className="img_pro">
              <img
                src={bio_metric}
                width="100%"
                height="100%"
                alt="Bio-metric"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BiometricPage;
