import React from 'react';
import "./Mail.css";

function Mail() {
  // Generate a list of years dynamically (e.g., from 2000 to current year + 5 for flexibility)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 20 }, (_, i) => currentYear - i); // Last 30 years

  return (
    <div className="mail-container">
      <div className="mail-card">
        <h1 className="mail-title">Contact Us</h1>
        <p className="mail-subtitle">We'd love to hear from you! Send us a message and we'll get back to you shortly.</p>

        <form action="mailto:kitkatsoftwaretechnologies@gmail.com" method="post" encType="text/plain" className="mail-form">
          <div className="form-group">
            <label htmlFor="first-name" className="form-label">First Name</label>
            <input type="text" id="first-name" name="first-name" className="form-input" placeholder="Enter your first name" maxLength="20" required />
          </div>

          <div className="form-group">
            <label htmlFor="last-name" className="form-label">Last Name</label>
            <input type="text" id="last-name" name="last-name" className="form-input" placeholder="Enter your last name" maxLength="20" required />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" id="email" name="email" className="form-input" placeholder="Enter your email" required />
          </div>

          <div className="form-group">
            <label htmlFor="phone" className="form-label">Mobile Number</label>
            <input type="tel" id="phone" name="phone" className="form-input" placeholder="Enter your mobile number" maxLength="10" required />
          </div>

          <div className="form-group">
            <label htmlFor="degree" className="form-label">Degree</label>
            <input type="text" id="degree" name="degree" className="form-input" placeholder="Enter your degree" required />
          </div>

          <div className="form-group">
            <label htmlFor="passout-year" className="form-label">Pass Out Year</label>
            <select id="passout-year" name="passout-year" className="form-input" required>
              <option  value="">Select Year</option>
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <button type="submit" className="submit-button">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Mail;

// import React, { useState } from "react";
// import "./Mail.css";

// function Mail() {
//   const [formData, setFormData] = useState({
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     degree: "",
//     passOutYear: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Construct the WhatsApp message
//     const message = `Hello, I would like to contact you. Here are my details:
//     - Name: ${formData.firstName} ${formData.lastName}
//     - Email: ${formData.email}
//     - Phone: ${formData.phone}
//     - Degree: ${formData.degree}
//     - Pass Out Year: ${formData.passOutYear}`;

//     // Encode the message for WhatsApp URL
//     const encodedMessage = encodeURIComponent(message);

//     // Redirect to WhatsApp
//     window.open(`https://wa.me/917010816299?text=${encodedMessage}`, "_blank");
//   };

//   return (
//     <div className="mail-container">
//       <div className="mail-card">
//         <h1 className="mail-title">Contact Us</h1>
//         <p className="mail-subtitle">
//           We'd love to hear from you! Send us a message on WhatsApp and we'll
//           get back to you shortly.
//         </p>

//         <form onSubmit={handleSubmit} className="mail-form">
//           <div className="form-group">
//             <label htmlFor="first-name" className="form-label">
//               First Name
//             </label>
//             <input
//               type="text"
//               id="first-name"
//               name="firstName"
//               className="form-input"
//               placeholder="Enter your first name"
//               value={formData.firstName}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="last-name" className="form-label">
//               Last Name
//             </label>
//             <input
//               type="text"
//               id="last-name"
//               name="lastName"
//               className="form-input"
//               placeholder="Enter your last name"
//               value={formData.lastName}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="email" className="form-label">
//               Email
//             </label>
//             <input
//               type="email"
//               id="email"
//               name="email"
//               className="form-input"
//               placeholder="Enter your email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="phone" className="form-label">
//               Mobile Number
//             </label>
//             <input
//               type="tel"
//               id="phone"
//               name="phone"
//               className="form-input"
//               placeholder="Enter your mobile number"
//               value={formData.phone}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="degree" className="form-label">
//               Degree
//             </label>
//             <input
//               type="text"
//               id="degree"
//               name="degree"
//               className="form-input"
//               placeholder="Enter your degree"
//               value={formData.degree}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <label htmlFor="passOutYear" className="form-label">
//               Pass Out Year
//             </label>
//             <input
//               type="text"
//               id="passOutYear"
//               name="passOutYear"
//               className="form-input"
//               placeholder="Enter your pass out year"
//               value={formData.passOutYear}
//               onChange={handleChange}
//               required
//             />
//           </div>

//           <div className="form-group">
//             <button type="submit" className="submit-button">
//               Send via WhatsApp
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Mail;
