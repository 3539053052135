import React from "react";
import "./Gold.css";
import Goldloan from "./Products/Gold loan management_Main.jpg";
import Goldloan_management from "./Products/GOLD_LOAN_MANAGEMENT_1.jpg";
import Goldloan_software from "./Products/GOLD_LOAN _MANAGEMENT_2.jpg";
import { Helmet } from "react-helmet-async";
const GoldPage = () => {
  return (
    <>
      <Helmet>
        <title> Gold loan software provider in Coimbatore</title>
        <meta
          name="description"
          content="Kitkatkat software technologies Gold loan software designed by our experts, user 
friendly& generates various types of reports that are useful for a gold loan. "
        />
        <meta
          name="keywords"
          content=" Best gold loan software service provider in Coimbatore "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={Goldloan}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              {" "}
              Gold loan software from KitKat Software Technologies is designed
              to help financial institutions, banking, and non-banking companies
              to manage the gold loans. Our software automates key processes
              such as loan processing, interest calculations, and customer
              management.
            </p>
            <div className="side_img1">
              <img
                src={Goldloan_management}
                width="100%"
                height="100%"
                alt="Goldloan Software"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              With KitKat Software Technologies Gold Loan Software, you can
              reduce the risk and develop the customer satisfaction level. We
              are here to help you to take your gold loan management system to
              the next level with advanced automation
            </p>
            <p className="cont_para_style">
              Our expert team designed this software to simplify the entire
              process from loan orientation to repayments with accuracy &
              security. Reach out to us we will customize the software to meet
              your specific requirements with the highest quality.
            </p>
            <div className="side_img1">
              <img
                src={Goldloan_software}
                width="100%"
                height="100%"
                alt="Goldloan Management"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldPage;
