import React from "react";
import "./About.css";
import aboutus from "../../Sliderimg/about_us.webp";
import Mission from "../../Sliderimg/Mission.webp";
import mission1 from "../../Sliderimg/mission (1).webp";
import vision from "../../Sliderimg/vision.webp";
import skill from "../../Sliderimg/skill.webp";
import { Helmet } from "react-helmet-async";
const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Best software training in Coimbatore| Web Design Coimbatore
        </title>
        <meta
          name="description"
          content="The best software providers in Coimbatore|10 years of Experience| Customized 
software solutions| Affordable cost| Internship With Certification."
        />
        <meta
          name="keywords"
          content="Best software development company in Coimbatore, best web development services, 
best software training  in coimbatore"
        />
      </Helmet>

      <div>
        <div>
          <img
            src={aboutus}
            width="100%"
            alt="Web Development Service"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="about_main_cont">
          <div className="about_cont_p">
            <p className="about_cont_para_style">
              At KitKat Software Technologies, we deliver high-quality digital
              solutions tailored to meet the unique needs of businesses across
              various industries. Our expertise spans Web Development,
              Application Development, Creative Design, Quality Assurance,
              Banking Services, and Billing Software.
            </p>
            <p className="about_cont_para_style">
              We take time to understand each client’s specific needs, ensuring
              we provide solutions that help them achieve their goals. Our team
              specializes in building custom websites that align with business
              objectives, offering seamless functionality and a user-friendly
              experience. We also provide ongoing maintenance services to ensure
              your website remains updated and performs at its best.
            </p>
            <p className="about_cont_para_style">
              With a customer-focused approach and a passion for innovation,
              KitKat Software Technologies is committed to delivering reliable,
              efficient, and future-ready solutions for businesses of all sizes.
            </p>
            <h1 id="who"> Our Approach</h1>
            <p className="about_cont_para_style">
              Your website is often the first interaction potential customers
              have with your business. That's why we focus on designing websites
              that are informative, visually appealing, and easy to navigate. A
              professionally designed website not only strengthens your brand’s
              credibility but also ensures a seamless user experience that
              converts visitors into customers.
            </p>{" "}
            <p className="about_cont_para_style">
              Selecting the right technology is critical for long-term success.
              Whether you need to optimize your existing system or build
              something new, our experts offer effective solutions that align
              with your business strategy.
            </p>{" "}
            <h1 id="who">Client-Centric Development</h1>
            <p className="about_cont_para_style">
              We believe in involving our clients at every stage of the
              development process. By understanding your business goals, we
              ensure that the final product is in line with your expectations.
              We adopt the best development methodologies such as Agile to
              ensure flexibility, collaboration, and continuous improvement.
            </p>
            <h1 id="who">Who we are</h1>
            <p style={{ fontSize: "18px", textAlign: "center", marginTop: 10 }}>
              <strong>We Create, Design and Make it Real</strong>
            </p>
            <p className="about_cont_para_style">
              Clients are made integral part of our team while executing the
              projects. This makes the audacious task of the software
              development and execution much simpler and easier, in tune with
              the client’s expectation. At I-Link, based on the situation and
              need of the project, we follow one of the following software
              development methodologies.Agile software development is a set of
              principles for software development in which requirements and
              solutions evolve through collaboration between self-organizing,
              cross-functional teams. It promotes adaptive planning,
              evolutionary development, early delivery, and continuous
              improvement, and it encourages rapid and flexible response to
              change. Agile itself has never defined any specific methods to
              achieve this, but many have grown up as a result and have been
              recognized as being 'Agile'.
            </p>
            <div className="about_page_flex_cont flex_about_b">
              <div className="our_skill flex_about">
                <h2 className="title2" id="skill">
                  Our Skills
                </h2>
                <p className="about_cont_para_style">
                  KITKAT is established primarily to carry out the offshore
                  software development projects and registered as a 100%
                  Software export unit. KITKAT has successfully delivered medium
                  and large size projects on time and under budget using the
                  Offshore Delivery Model with the help of Managerial and
                  Technical skill sets. With an experience of over 8+ years now,
                  KITKAT has adapted to various changes to understand the cross
                  culture needs of the region specific customers.
                </p>
              </div>
              <div className="about_image">
                <img
                  src={Mission}
                  width="100%"
                  height="100%"
                  alt="Kitkat Mission"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="about_page_flex_cont flex_about_b">
              <div class="about_image1">
                <img
                  src={vision}
                  width="100%"
                  height="100%"
                  alt="Kitkat Vision"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="our_skill flex_about2">
                <h2 class="title2" id="vision">
                  Our Vision
                </h2>
                <p class="about_cont_para_style">
                  To be a premier information technology solutions and services
                  provider, by excelling in fulfilling the needs of its
                  customers, partners and employees, by providing the most
                  efficient and effective services and solutions, utilizing the
                  best resources. We provide highly qualified and experienced
                  Resources at a fraction of onsite prices. We meet any
                  challenging schedule using our large pool of Offshore
                  resources.
                </p>
              </div>
              <div className="about_img_media">
                <img
                  src={vision}
                  width="100%"
                  height="100%"
                  alt="Vision"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="about_page_flex_cont flex_about_b">
              <div className="our_skill flex_about">
                <h2 className="title2" id="mission">
                  Our Mission
                </h2>
                <p className="about_cont_para_style">
                  Our mission is to progressively realize our vision by
                  providing innovative, proactive, efficient, cost-effective,
                  quality solutions and services to our clients all over the
                  world and by building a strong internal value system that
                  provides the transparency and integrity in all the
                  transactions among our employees, partners and customers.The
                  methodology enforces strict adherence to adequate
                  Documentation, Quality Control Processes and Client
                  Communication.
                </p>
              </div>
              <div className="about_image">
                <img
                  src={mission1}
                  width="100%"
                  height="100%"
                  alt="Mission"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div class="about_page_flex_cont flex_about_b">
              <div class="about_image1">
                <img
                  src={skill}
                  width="100%"
                  height="100%"
                  alt="Professional Skills"
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div className="our_skill flex_about2">
                <h2 className="title2" id="offering">
                  Our Offerings
                </h2>
                <p class="about_cont_para_style">
                  We offer end-to-end solutions to the customers from Hardware
                  to Software,Network to Live Remote-Support,Embedded Systems to
                  Project Assistance. We work meticlously to provide best in
                  class services.
                </p>
              </div>
              <div className="about_img_media">
                <img
                  src={skill}
                  width="100%"
                  height="100%"
                  alt="Professional Skills"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AboutPage;
