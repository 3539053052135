import React from "react";
import "./Webhost.css";

import web_hosting from "./Services/customized application development1.jpg";
import Internet_hosting from "./Services/WEB HOSTING1.jpg";
import web_hosting_service from "./Services/WEB HOSTING 2.jpg";
import { Helmet } from "react-helmet-async";
const WebhostPage = () => {
  return (
    <>
      <Helmet>
        <title>Custom Software Services in Coimbatore| Kitkat Software</title>
        <meta
          name="description"
          content=" The best  custom Web Application Development Company in Coimbatore,offering 
customized software services for developing your business|kitkat software Technology"
        />
        <meta
          name="keywords"
          content="Custom Software Services in Coimbatore, software development companies near 
me"
        />
      </Helmet>
      <div>
        <div>
          <img
            src={web_hosting}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              Kitkat software Technologies we are the professionals in providing
              innovative software web development service, specialized in
              customized application development. Our team design and create the
              high quality websites through the deep understanding of our
              clients needs{" "}
            </p>

            <div className="img_pro">
              <img
                src={Internet_hosting}
                alt="Images"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Your success is our priority to deliver the exceptional outcomes. 
              Our team takes time to deeply understand your business needs to
              meet your goals. Feel free to contact us; let us help you
              transform your vision into customized and high performing
              applications.
            </p>

            <div className="img_pro">
              <img
                src={web_hosting_service}
                alt="Images"
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebhostPage;
