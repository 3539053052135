import React from "react";
import "./Course.css";
import php from "../courseimage/php.png";
import python from "../courseimage/python.jpg";
import java from "../courseimage/java-logo-1.png";
import pwa from "../courseimage/pwa.png";
import javascript from "../courseimage/javascript.png";
import jquery from "../courseimage/jquery.png";
import yii from "../courseimage/yii-logo-.png";
import codeigniter from "../courseimage/codeigniter.jpg";
import laravel from "../courseimage/laravel.png";
import drupal from "../courseimage/drupal.png";
import django from "../courseimage/django.png";
import Angular from "../courseimage/Angular.png";
import nodejs from "../courseimage/nodejs.jpg";
import react from "../courseimage/react.png";
import Spring from "../courseimage/Spring.png";
import Mysql from "../courseimage/mysql.png";
import SQLite from "../courseimage/SQLite.png";
import PostgreSQL from "../courseimage/PostgreSQL.png";
import MangoDB from "../courseimage/MangoDB.png";
import Androidapp from "../courseimage/Android_app.png";
import ios_application from "../courseimage/ios_application.png";
import React_native from "../courseimage/React_native.png";
import IO from "../courseimage/IO.png";
import FL from "../courseimage/FL.png";
import XA from "../courseimage/XA.png";
import oracle from "../courseimage/oracle.jpeg"
import dotnet from "../courseimage/dotnet.jpeg"

const CourseContent = () => {
  return (
    <div className="course-container">
      <div className="software-tools">
        <h2 className="section-title">Framework</h2>
        <div className="tools-grid">
          {[react, java, php, python, pwa, javascript, jquery,dotnet].map((src, index) => (
            <img key={index} src={src} className="img-logo" alt="Programming Language" />
          ))}
        </div>

        <h2 className="section-title">Software Tools</h2>
        <div className="tools-grid">
          {[laravel, yii, codeigniter, drupal, django, Angular, nodejs, Spring].map((src, index) => (
            <img key={index} src={src} className="img-logo" alt="Software Tool" />
          ))}
        </div>

        <h2 className="section-title">Database</h2>
        <div className="tools-grid">
          {[Mysql, SQLite, PostgreSQL, MangoDB,oracle].map((src, index) => (
            <img key={index} src={src} className="img-logo" alt="Database" />
          ))}
        </div>

        <h2 className="section-title">Mobile Application Tools</h2>
        <div className="tools-grid">
          {[Androidapp, ios_application, React_native, IO, FL, XA].map((src, index) => (
            <img key={index} src={src} className="img-logo" alt="Mobile App Tool" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
