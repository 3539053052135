import React, { useContext, useEffect, useState } from "react";
import "./Training.css";
import { Link } from "react-router-dom";
import { Training_details } from "../TrainingPage/Training_details/Training_datas";
import { contextData } from "../../App";

const TrainingDropdown = () => {
   const context_data = useContext(contextData)
    let {handle}=context_data
  const [serclick, setSerClick] = useState(false);

  const handleClick = () => setSerClick(!serclick);

  // const [ind , setind] = useState(null)
  //   function moving(index){
  
  
  // setind(index)
      
  // console.log(ind);
      
  
  //   }

  //   useEffect(() => {
  //     console.log("Updated ind:", ind); // Logs the updated value after re-render

  //     localStorage.setItem("moving" , JSON.stringify(ind))

  //   }, [ind]);



  return (
    <>
      <ul
        onClick={handleClick}
        className={serclick ? "drop_down_cont clicked" : "drop_down_cont"}
      >
        {Training_details.map((item, index) => {
          return (
            <li key={index} >
              <Link
                className="drop_down_cont2"
                to="/Status"
                onClick={() => {
                  setSerClick(false)

                   handle(item ,item.sub)
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TrainingDropdown;