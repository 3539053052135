import React from "react";
import "./Webdesign.css";
import static_website from "./Services/website design and development.jpg";
import dynamic_website from "./Services/WEBSITE DESIGN & DEVELOPMENT_1.jpg";
import web_app from "./Services/WEBSITE DESIGN & DEVELOPMENT_2.jpg";
import { Helmet } from "react-helmet-async";

const WebdesignPage = () => {
  return (
    <>
      <Helmet>
        <title> Website Design Company in Coimbatore| Kitkat Software </title>
        <meta
          name="description"
          content="  Looking for a top website design company in Coimbatore? We customize and 
create astonishing websites. Your trusted partner with over 10 years of excellence.  "
        />
        <meta
          name="keywords"
          content=" Website Design Company in Coimbatore "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={static_website}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              If you are a business owner and unsure about choosing the right
              website development service providers for your business. At KitKat
              Software Technologies, our team has the highly experienced
              developers to design and develop the website to meet your goals.
            </p>

            <div class="img_pro">
              <img
                src={dynamic_website}
                width="100%"
                height="100%"
                alt="Static Website"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              We are specialized in Designing and developing the website based
              on the indepth research of your business. Our Expert team create
              fully customized the website tailored to align with your mission
              and goal.{" "}
            </p>

            <p className="cont_para_style">
              We are ready to design and develop all type of websites like
              static/ dynamic and E-commerce. Our team will provide you the
              customized website solution for your Business.
            </p>
            <div className="img_pro">
              <img
                src={web_app}
                width="100%"
                height="100%"
                alt="Dynmaic Website"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WebdesignPage;
