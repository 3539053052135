import React from "react";
import "./Gold.css";
import matrimonialmain from "./Products/matrimonial1.jpg";
import matri1 from "./Products/MATRIMONIAL_1.jpg";
import matri2 from "./Products/MATRIMONIAL_2.jpg";
import { Helmet } from "react-helmet-async";
const Matrimonial = () => {
  return (
    <>
      <Helmet>
        <title>Matrimonial Website Development Company in Coimbatore</title>
        <meta
          name="description"
          content="Get customizable matrimonial website software from Kitkat software technologies| 
software solutions Coimbatore. "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={matrimonialmain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              {" "}
              Kitkat software technologies was established in the year . Trusted
              partner in expert matrimonial website services. In the dynamic
              world it is hard to find the right partner for your future. In
              today’s era our well excellent matrimonial website development
              services provides trustable platform for your wedding dream.
            </p>

            <div className="side_img1">
              <img
                src={matri1}
                width="100%"
                height="100%"
                alt="Goldloan Software"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Are you looking for the best matrimonial website developers?
            </p>
            <p className="cont_para_style">
              Our skilled and experienced professionals are here to fulfill all
              your needs. We will assist you to create your matrimonial websites
              with the features likes profile verification, profile matching
              score, Filtering options based on your requirements.
            </p>
            <p className="cont_para_style">
              Feel free to contact us and launch your matrimonial website . We
              will customize your website with our experienced designers and
              developers as per your requirements in order to meet your
              missions.
            </p>
            <div className="side_img1">
              <img
                src={matri2}
                width="100%"
                height="100%"
                alt="Goldloan Management"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matrimonial;
