import React from "react";
import "./Office.css";
import erpuser from "../Products/img/erpp1.jpg";
import erp from "../Products/img/erpm3.jpg";
import Enterprise_resource from "../Products/img/erpp2.jpg";

const OfficeERPPage = () => {
  return (
    <>
      {/* Hero Section */}
      <div>
        <div>
          <img
            src={erpuser}
            width="100%"
            alt="ERP"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      {/* Content Section */}
      <div className="product_cont">
        <div className="career_p">
          <h1 className="product_title">Enterprise Resource Planning</h1>
          <p className="cont_para_style">
            ERP (Enterprise Resource Planning) software helps businesses manage
            day-to-day operations such as accounting, purchasing, project
            management, risk management, and supply chain activities. It also
            provides tools for budgeting, forecasting, and reporting, helping
            businesses optimize their financial performance.
          </p>
          <div className="img_pro">
            <img
              src={erp}
              width="100%"
              alt="ERP Web"
              className="img1"
              style={{ objectFit: "cover" }}
            />
          </div>
          <p className="cont_para_style">
            KitKat Software Technologies offers a cloud-based ERP solution that
            enables businesses to streamline operations, track resources
            efficiently, and make data-driven decisions. Our system provides
            real-time visibility into ongoing projects, automates key processes,
            and reduces planning time, ensuring smoother execution.
          </p>
          <div className="img_pro">
            <img
              src={Enterprise_resource}
              width="100%"
              height="100%"
              alt="Enterprise Resource Planning"
              className="img1"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions Section */}
      <div className="key" style={{ padding: "20px", marginTop: "20px" }}>
        <h2>Frequently Asked Questions</h2>
        <ul style={{ fontSize: "18px", lineHeight: "24px" }}>
          <li>
            <strong>1. What is ERP software and why is it important?</strong>
            <br />
            ERP software helps streamline core business functions like accounting
            and project management, increasing efficiency and reducing manual
            work.
          </li>
          <li>
            <strong>2. How does ERP improve financial performance?</strong>
            <br />
            By offering tools for budgeting, forecasting, and reporting, ERP
            software helps businesses manage expenses and resources more
            effectively.
          </li>
          <li>
            <strong>
              3. What are the benefits of using a cloud-based ERP system?
            </strong>
            <br />
            A cloud-based ERP system offers real-time project visibility, task
            automation, and reduces redundant work, making business management
            easier.
          </li>
          <li>
            <strong>
              4. How does KitKat Software Technologies help businesses manage
              projects?
            </strong>
            <br />
            We offer an intuitive dashboard to monitor ongoing projects and
            optimize resource allocation, improving productivity and project
            success.
          </li>
          <li>
            <strong>
              5. Can ERP and project management software work together?
            </strong>
            <br />
            Yes, integrating ERP with project management tools enhances
            efficiency by connecting financial data with project tracking.
          </li>
        </ul>
      </div>
    </>
  );
};

export default OfficeERPPage;