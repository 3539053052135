import React from "react";
import "./Gold.css";
import minimain from "./Products/Minibanking1.jpg";
import mini1 from "./Products/MINIBANKING_1.jpg";
import mini2 from "./Products/MINIBANKING_2.jpg";
import { Helmet } from "react-helmet-async";
const MinibankPage = () => {
  return (
    <>
      <Helmet>
        <title> Banking software service providers in Coimbatore </title>
        <meta
          name="description"
          content=" At KitKat Software Technologies, we provide hassle-free mini banking software 
solutions at an affordable cost. Secure, efficient & tailored to your needs. "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={minimain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              {" "}
              Mini Banking from KitKat Software Technologies is a secure and
              efficient financial management system designed for businesses of
              all sizes. This software helps the companies to maintain a smooth
              banking process.
            </p>

            <div className="side_img1">
              <img
                src={mini1}
                width="100%"
                height="100%"
                alt="Goldloan Software"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>

            <p className="cont_para_style">
              Kitkat software technologies Mini banking software is designed for
              ease of use, and it is designed to provide personalized dashboards
              for users to monitor. This dashboard allows users to monitor key
              financial metrics, account balances, and transaction histories at
              a glance to ensure seamless management.
            </p>
            <p className="cont_para_style">
              Our expert developers at Kikat Software Technologies design
              customized software for your business tailored to your business
              size, specific requirements & industry needs. We conduct in-depth
              research to understand your business goal. Ensuring cost-effective
              software service in Coimbatore
            </p>
            <div className="side_img1">
              <img
                src={mini2}
                width="100%"
                height="100%"
                alt="Goldloan Management"
                className="img1"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinibankPage;
