import React from "react";
import "./Showsub3.css";
import { Link } from "react-router-dom";
import { Training_details } from "../TrainingPage/Training_details/Training_datas";
import { useState } from "react";
import { useContext } from "react";
import { contextData } from "../../App";

const MediaTrainingDropdown = ({
  isOpenicon3,
  setIsOpenicon3,
  showsub3,
  handleClose,
}) => {
  const [ind , setind] = useState(null)
  const context_data = useContext(contextData)
  let {handle} = context_data
  function moving(index){

   setind(index)
    
console.log(ind);
//  localStorage.setItem("moving",JSON.stringify(ind))
    

  }
  return (
    <>
      <ul
        className={isOpenicon3 ? "drop_down_cont_media" : "none"}
        onClick={showsub3}
      >
        {Training_details.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className="drop_down_cont_media"
                 to="/Status"
                onClick={() => {
                  setIsOpenicon3(!isOpenicon3);
                  handleClose();
                  handle(item,item.sub)
                  moving(index)
                }}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default MediaTrainingDropdown;
