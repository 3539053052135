import React, { useEffect } from "react";
import "./Status.css";
import { useLocation } from "react-router-dom";
import Skills from "../TrainingPage/Skills/Skills";

function Status() {
  const location = useLocation();

  // Ensure page scrolls to top when navigating
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scrolling to top
    }, 50); // Delay to ensure React updates DOM before scrolling
  }, [location.pathname]); // Use pathname to trigger scroll update

  return (
    <div className="total_status_connect">
      <Skills key={location.pathname} />
    </div>
  );
}

export default Status;
