const ProductItems = [
  {
    id: 13,
    title: "E-Commerce",
    path: "/E-CommercePage",
    cName: "drop_down_cont1",
  },
  {
    id: 27,
    title: "CRM",
    path: "/CRMPage",
    cName: "drop_down_cont2",
    idName: "drops2",
  },
  {
    id: 28,
    title: "ERP",
    path: "/ERPPage",
    cName: "drop_down_cont2",
    idName: "drops2",
  },

  {
    id: 11,
    title: " Biometric Based HRM",
    path: "/BiometricPage",
    cName: "drop_down_cont1",
    idName: "drops1",
  },
  {
    id: 31,
    title: "School ERP",
    path: "/SchoolErp",
    cName: "drop_down_cont1",
    idName: "drops1",
  },
  {
    id: 17,
    title: "Gold Loan Management",
    path: "/GoldLoanPage",
    cName: "drop_down_cont1",
    idName: "drops1",
  },
  {
    id: 32,
    title: "Matrimonial Website",
    path: "/matrimonial",
    cName: "drop_down_cont1",
    idName: "drops1",
  },
  {
    id: 18,
    title: "Medical Billing",
    path: "/MedicalPage",
    cName: "drop_down_cont1",
    idName: "drops1",
  },
 
  {
    id: 14,
    title: "Mini Banking",
    path: "/MiniBankingPage",
    cName: "drop_down_cont1",
    idName: "drops1",
  },

  {

    id:12,
    title: 'View All',
    path: '/allproducts',
    cName: 'drop_down_cont1',
    idName:'drops1'
    },

  //   { id:16,
  //       title: 'POS On Billing',
  //       path: '/POSPage',
  //       cName: 'drop_down_cont1',
  //       idName:'drops1'
  //     },
];

export default ProductItems;
