import React from "react";
import "./Ecommerce.css";
import ecommercemain from "./Products/ecommerce1.avif";
import Ecommerce1 from "./Products/EC_1.jpg";
import Ecommerce2 from "./Products/EC_2.jpg";
import { Helmet } from "react-helmet-async";

const EcommercePage = () => {
  return (
    <>
      <Helmet>
        <title>E-Commerce Website Development in Coimbatore | Kitkat</title>

        <meta
          name="description"
          content="The best E commerce website developers . We customize your E-commerce 
website user friendly |Best software development company in Coimbatore"
        />
        <meta
          name="keywords"
          content="E-Commerce website development company"
        />
      </Helmet>
      {/* Hero Section */}
      <div>
        <div>
          <img
            src={ecommercemain}
            width="100%"
            alt="E-commerce"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      {/* Content Section */}
      <div className="product_cont">
        <div className="career_p">
          <p className="cont_para_style">
            At KitKat Software Technologies, we specialize in developing
            user-friendly e-commerce platforms that cater to businesses of all
            sizes. Our websites are designed to showcase products in an
            organized and engaging way, making the shopping experience seamless
            for customers. We focus on global accessibility, ensuring smooth and
            hassle-free transactions, no matter where your customers are
            located.
          </p>
          <div className="img_pro">
            <img
              src={Ecommerce1}
              width="100%"
              height="100%"
              className="img1"
              alt="Ecommerce-website"
              style={{ objectFit: "cover" }}
            />
          </div>
          <p className="cont_para_style">
            As one of the leading web design companies in Coimbatore, we take a
            personalized approach to each project. We make sure to understand
            your business needs, including industry type, promotional
            activities, and competitors, ensuring every project is customized to
            meet your specific goals.
          </p>
          <div className="img_pro">
            <img
              src={Ecommerce2}
              width="100%"
              height="100%"
              alt="Digital Marketing"
              className="img1"
              style={{ objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EcommercePage;
