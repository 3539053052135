import img1 from "./1.png"
import img2 from "./2.png"
import img3 from "./3.jpeg"
import img4 from "./4.jpeg"
import img5 from "./5.jpeg"
import img6 from "./6.png"
import img7 from "./7.jpeg"
import img8 from "./8.png"
import img9 from "./9.jpeg"
import img10 from "./10.jpeg"
import img11 from "./11.jpeg"
import img12 from "./12.jpeg"
import img13 from "./13.jpeg"
import img14 from "./14.png"
import img15 from "./15.jpeg"
import img16 from "./16.jpeg"
import img17 from "./17.jpeg"
import img18 from "./18.jpeg"
import img19 from "./19.png"
import img20 from "./20.png"
import img21  from "./21.jpeg"
import img22 from "./22.png"
import img23 from "./23.jpeg"
import img24 from "./24.jpeg"
import img25 from "./25.jpeg"
import img26 from "./26.jpeg"
import img27 from "./27.jpeg"
import img28 from "./28.jpeg"
import img29 from "./29.png"
import img30 from "./30.jpeg"
import img31 from "./31.jpeg"
import img32 from "./32.png"
import img33 from "./33.png"
import img34 from "./34.png"
import img35 from "./35.png"
import img36 from "./36.jpeg"
import img37 from "./37.jpeg"
import img38 from "./38.png"
import img39 from "./39.jpeg"
import img40 from "./40.jpeg"
import img41 from "./41.jpeg"
import img42 from "./42.jpeg"
import img43 from "./43.jpeg"
import img44 from "./44.jpeg"
import img45 from "./45.jpeg"
import img46 from "./46.png"
import img47 from "./47.png"
import img48 from "./48.jpeg"
import img49 from "./49.jpeg"
import img50 from "./50.jpeg"
import img51 from "./51.jpeg"
import img52 from "./52.jpeg"
import img53 from "./53.jpeg"
import img54 from "./54.png"
import img55 from "./55.jpeg"
import img56 from "./56.png"
import img57 from "./57.jpeg"
import img58 from "./58.jpeg"
import img59 from "./59.jpeg"
import img60 from "./60.jpeg"
import img61 from "./61.png"
import img62 from "./62.jpeg"
import img63 from "./63.png"
import img64 from "./64.png"
import img65 from "./65.png"
import img66 from "./66.jpeg"
import img67 from "./67.png"
import img68 from "./68.jpeg"
import img69 from "./69.jpeg"
import img70 from "./70.jpeg"
import img71 from "./71.jpg"
import img72 from "./72.jpg"
import img73 from "./73.jpg"
import img74 from "./74.jpg"
import img75 from "./75.jpg"
import img76 from "./76.jpg"
import img77 from "./77.jpg"
import img78 from "./78.jpg"
import img79 from "./79.jpg"
import img80 from "./80.jpg"
import img81 from "./81.png"
import img82 from "./82.png"
import img83 from "./83.jpg"
import img84 from "./84.jpg"
import img85 from "./85.jpg"
import img86 from "./86.jpg"
import img87 from "./87.jpg"
import img88 from "./88.jpg"
import img89 from "./89.jpg"
import img90 from "./90.png"
import img91 from "./91.jpg"


export const productimages= {
    img1 ,
    img2 ,
    img3 ,
    img4 ,
    img5, 
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img52,
    img53,
    img54,
    img55,
    img56,
    img57,
    img58,
    img59,
    img60,
    img61,
    img62,
    img63,
    img64,
    img65,
    img66,
    img67,
    img68,
    img69,
    img70,
    img71,
    img72,
    img73,
    img74,
    img75,
    img76,
    img77,
    img78,
    img79,
    img80,
    img81,
    img82,
    img83,
    img84,
    img85,
    img86,
    img87,
    img88,
    img89,
    img90,
    img91
}