import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaLightbulb, FaBalanceScale, FaDollarSign } from "react-icons/fa";
import "./mid.css";

const features = [
  { icon: <FaLightbulb />, title: "Expertise", desc: "Our team brings innovation and expertise to every project." },
  { icon: <FaBalanceScale />, title: "Scalability", desc: "Solutions designed to grow with your business needs." },
  { icon: <FaDollarSign />, title: "Cost Efficiency", desc: "Delivering high-quality solutions while optimizing costs." },
];

const iconColors = [
  { inner: "#FEE715", outer: "#101820" }, // Blue Bulb
  { inner: "#31473A", outer: "#EDF4F2" }, // Green Scale
  { inner: "#F98866", outer: "#FFF2D7" }  // Orange Dollar
];

const Mid = () => {
  return (
    <div className="mid-section">
      <div className="features">
        <h2>Why Choose <span className="highlight">Kitkat Software Technologies</span></h2>

        <div className="feature-grid">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-box"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <motion.div
                className="feature-icon"
                style={{
                  color: iconColors[index].inner, // Inner Color
                  filter: `drop-shadow(0px 0px 5px ${iconColors[index].outer})` // Outer Glow Effect
                }}
                whileHover={{ filter: "drop-shadow(0px 0px 10px gold)" }} // Gold Glow on Hover
                transition={{ duration: 0.5 }}
              >
                {feature.icon}
              </motion.div>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mid;
