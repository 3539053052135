import { BrowserRouter, useNavigate } from "react-router-dom";
import HeaderPage from "./Components/Header/Header";
import MainLink from "./Components/Links/Links";
import Animationshape from "./Components/Animationshape/Animationshape";
import FooterPage from "./Components/Footer/Footer";
import SocialLink from "./Components/Sociallink/Sociallink";
import { createContext, useEffect, useRef, useState } from "react";
import ScrollToTop from "./Components/scorltop/scrolltop";
import { HelmetProvider } from "react-helmet-async";
// import Preloader from "./preLoader";
// import NavPage from "./Components/Navbar/Navbar";

export const contextData = createContext(null)
function App() {



  document.body.style.overflow = 'auto'; 
 

  const [blur,setblur] = useState (false)
  const adbanner  = useRef()
  

  const [tools ,settools] = useState ({id: "",pdf:"", img: "", title: "", sub: "" ,tools:""})
  const [subject , setsubject] =useState (null)

  function handle( item, sub){
      settools(item)
      setblur(true)
    setsubject(sub)
console.log(tools);

 

  }

  function closeBanner() {
    
    if (adbanner.current) {
      adbanner.current.style.display = 'none'; 
      // setref(false) 
      adbanner.current.classList.remove('show-ad'); 
      setblur(false)
    }
    document.body.style.overflow = 'auto'; 
  }
    

  //  const navigate = useNavigate()

 function mailsend( title){
console.log(title);
console.log("hiii");

  // navigate("/Mail")
 }


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <>
   <HelmetProvider>
     <contextData.Provider value={{handle , tools ,subject,blur,adbanner,closeBanner,mailsend}}>
      <BrowserRouter>
      <ScrollToTop/>
         {/* <Animationshape /> */}

        <HeaderPage />
        {/* <NavPage/> */}
        <SocialLink />

        <MainLink />
        <FooterPage />
      </BrowserRouter>
      </contextData.Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
