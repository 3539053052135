// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "./Careers.css";
// import career from "../../Sliderimg/career (1).webp";
// import java from "../../courseimage/java.png";
// import php from "../../courseimage/php.png";
// import react from "../../courseimage/react.png";
// import nodejs from "../../courseimage/nodejs.jpg";
// import axios from "axios";

// const CareersPage = () => {
//   const [file, setFile] = useState("");

//   //   const send = async (e) => {
//   //     e.preventDefault();

//   //     const formData = new FormData();
//   //     formData.append("name", name);
//   //     formData.append("email", email);
//   //     formData.append("file",file);

//   //    axios.post(
//   //     "http://localhost:1000/post", formData)
//   //     .then((res)=>console.log(res))
//   //     .catch((err)=>console.log(err))

//   // };

//   const submitImage = async (e) => {
//     e.preventDefault();

//     if (!file) {
//       alert("Please upload a resume before submitting.");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     // Prepare EmailJS parameters
//     const emailParams = {
//       to_name: "Your Name",
//       user_email: "your_email@example.com",
//       message: "A new resume has been uploaded.",
//       file_url: URL.createObjectURL(file), // Temporary URL for local preview
//     };

//     emailjs
//       .send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", emailParams, "YOUR_PUBLIC_KEY")
//       .then(() => {
//         alert("Resume sent successfully!");
//         setFile("");
//       })
//       .catch((err) => console.error("Error sending email:", err));
//   };
//   const onInputChange = (e) => {
//     console.log(e.target.files[0]);
//     setFile(e.target.files[0]);
//   };
//   function showAlert() {
//     alert("Resume uploaded successfully!");
//   }

//   return (
//     <>

//       <div>
//         <div>
//           <img
//             src={career}
//             width="100%"
//             alt="IT Career"
//             style={{
//               objectFit: "cover",
//               marginTop: "80px",
//               maxHeight: "400px",
//             }}
//           />
//         </div>
//       </div>

//       <div className="content_lists">
//         <div className="about_main_cont">
//           <div className="career_p">
//             <strong style={{ fontSize: "21px" }}>
//               If you are looking to work on challenging assignments in leading
//               fortune 500 companies and looking to advance your professional
//               career, you are at the right place.
//             </strong>
//             <p className="cont_para_style" style={{ width: "100%" }}>
//               {" "}
//               Our firm is always on the lookout for individuals with the drive
//               and talent to make a difference through their skill and
//               dedication. We are always looking for people who can add to the
//               talent and skill set of our team while also emerging as leaders by
//               bringing new perspectives and dimensions to the work. Kitkat
//               Software Technologies has several job openings right now. Kitkat
//               Technologies is always on the hunt for talented people. Please
//               apply if you believe you have the skills and passion to work for
//               Coimbatore's best web development company. The positions listed
//               below are currently available:
//             </p>
//           </div>
//         </div>
//         <div className="career_img">
//           <div className="career_flex">
//             <div className="career_flex_img">
//               <img
//                 src={react}
//                 width="100%"
//                 height="100%"
//                 alt="react JS"
//                 style={{ objectFit: "contain" }}
//               />
//             </div>
//             <div className="career_flex_img">
//               <img
//                 src={php}
//                 width="100%"
//                 height="100%"
//                 alt="Php Developer"
//                 style={{ objectFit: "contain" }}
//               />
//             </div>
//             <div className="career_flex_img">
//               <img
//                 src={java}
//                 width="100%"
//                 height="100%"
//                 alt="Java Programming"
//                 style={{ objectFit: "contain" }}
//               />
//             </div>
//             <div className="career_flex_img">
//               <img
//                 src={nodejs}
//                 width="100%"
//                 height="100%"
//                 alt="Node Js"
//                 style={{ objectFit: "contain" }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="carrear_table_cont">
//         <div className="carrear_flex">
//           <div className="carrear_table">
//             <div className="quick_links">
//               <div className="heading">Coimbatore Location</div>
//               <div style={{ color: "#666", padding: "10px" }}>
//                 <strong>Currently Hiring For : </strong>
//               5+ Years Experience
//               </div>
//               <div className="liststyle">
//                 <ul>
//                   <li>
//                     <Link to="/ContactPage">Java Developers</Link>
//                   </li>
//                   <li>
//                     <Link to="/ContactPage">PHP Developers</Link>
//                   </li>

//                   <li>
//                     <Link to="/ContactPage">Node JS</Link>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//           <div className="carrear_table">
//             <div className="quick_links">
//               <div className="heading">Coimbatore Location</div>
//               <div style={{ color: "#666", padding: "10px" }}>
//                 Freshers &amp; 0-1 Year Experience(female)
//               </div>
//               <div className="liststyle">
//                 <ul>
//                   <li>
//                     <Link to="/ContactPage">Business Developer</Link>
//                   </li>
//                   <li>
//                     <Link to="/ContactPage">SEO Analyst</Link>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <div className="resumecontainer">
//           <h1>Upload Resume</h1>
//           <form method="POST" onSubmit={submitImage}>
//             <div className="fileUploading">
//               <input
//                 class="res_input"
//                 name="name"
//                 type="text"
//                 placeholder="Enter Your Name"
//                 required
//               />
//             </div>
//             <div className="fileUploading">
//               <input
//                 class="res_input"
//                 name="email"
//                 type="email"
//                 placeholder="Enter Your Email"
//                 required
//               />
//             </div>
//             <div className="fileUploading">
//               <input
//                 name="resume"
//                 class="res_input"
//                 type="file"
//                 onChange={onInputChange}
//                 style={{ paddingTop: 5 }}
//               />
//             </div>
//             <button
//               name="upload"
//               className="upload"
//               type="submit"
//               value="Upload"
//               onClick={showAlert}
//             >
//               Upload
//             </button>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default CareersPage;

import React, { useState } from "react";
import team from "./hiring-concept-with-people-coming-together_23-2149519873.avif";
import axios from "axios";
import "./Careers.css";
import { Helmet } from "react-helmet-async";

function Careers() {
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   resume: null,
  // });

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleFileChange = (e) => {
  //   setFormData({ ...formData, resume: e.target.files[0] });
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   const formDataObject = {
  //     name: formData.name,
  //     email: formData.email,
  //     message: "Resume submission", // Required field for Formspree
  //   };
  
  //   try {
  //     const response = await axios.post(
  //       "https://formspree.io/f/mvgkgqnj", // ✅ Fixed URL
  //       formDataObject,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  
  //     if (response.status === 200) {
  //       alert("Form sent successfully!");
  //     } else {
  //       alert("Failed to send form.");
  //     }
  //   } catch (error) {
  //     alert("Error sending form.");
  //   }
  // };
  
  return (
    <>
    <Helmet>
            <title>
            No.1 Training Institute in Coimbatore| 100 % Placement 
            </title>
            <meta
              name="description"
              content=" Looking for a rewarding career? Your dream job starts here. Join our team of 
experts and be a part of something great. Apply now."
            />
            <meta
              name="keywords"
              content="Top web development training with certification, Software Training Courses in 
Coimbatore"
            />
          </Helmet>
      <div className="careers-container">
        {/* Hero Section */}
        <img src={team} alt="Join Our Team" className="hero-image" />
        <div></div>

        <div className="join-team">
          <h1 className="contents">Join Our Team</h1>
          <p className="p-content">
            If you are looking to work on challenging assignments in leading
            fortune 500 companies and looking to advance your professional
            career, you are at the right place.
          </p>
        </div>

        {/* Why Work With Us? */}
        <section className="careers-info">
          <h2 className="contents">Why Work With Us?</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <span>🌟</span>
              <h3>Impactful Projects</h3>
              <p className="p-content">
                Work on real-world projects that make a difference.
              </p>
            </div>
            <div className="benefit-card">
              <span>🤝</span>
              <h3>Collaborative Culture</h3>
              <p className="p-content">
                Be part of a supportive and innovative team.
              </p>
            </div>
            <div className="benefit-card">
              <span>🚀</span>
              <h3>Career Growth</h3>
              <p className="p-content">
                Unlock new opportunities and grow with us.
              </p>
            </div>
          </div>
        </section>

        {/* Job Listings */}
        <section className="job-listings">
          <h2 className="contents">Find The Right Job</h2>
          <div className="jobs-grid">
            <div className="job-card">
              <h3 className="contents">React+Node Fullstack Developer</h3>
              <p className="p-content">4 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Business Developer</h3>
              <p className="p-content">0-1 year experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">SEO Analyst</h3>
              <p className="p-content">2-4 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Admin</h3>
              <p className="p-content">Fresher</p>
              <button className="learn-more-button">Learn More</button>
            </div>
            <div className="job-card">
              <h3 className="contents">Telecaller</h3>
              <p className="p-content">Minimum 2 years experience</p>
              <button className="learn-more-button">Learn More</button>
            </div>
          </div>
        </section>

        {/* Internship Section */}
        <section className="internship-section">
          <h2 className="contents">INTERNSHIP</h2>
          <p className="p-content">
            For the past decade Kitkat software technologies providing
            internship with certification. Aspiring to kick start your career in
            Software industry For that we offer fantastic opportunity to join
            with kitkat software technologies and make hands on experience and
            recognized certification.
          </p>
          <p className="p-content">
            Our internship programmes are tailored to College students who are
            ready to build strong foundation to their career. As an leading
            Company we provide the hands on experience with affordable cost
          </p>
          <p className="p-content">
            Contact us to know more about the Internship training program at
            kitkat software technologies.
          </p>
        </section>

        {/* Resume Upload Form */}
        <section className="resume-upload">
        <h2 className="contents">Upload Your Resume</h2>
        <form className="resume-form" >
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <input type="file" name="resume" required />
          <button type="submit">Upload & Send</button>
        </form>
      </section>
      </div>
    </>
  );
}

export default Careers;
