import React from "react";
import "./Hrm.css";
import youmain from "./Services/insta & youtube ads.jpg";
import you1 from "./Services/YOUTUBE & INSTA ADS_1.jpg";
import you2 from "./Services/YOUTUBE & INSTA ADS_2.jpg";
import { Helmet } from "react-helmet-async";
const YouTube = () => {
  return (
    <>
      <Helmet>
        <title> Best Social media marketing service in Coimbatore|Kitkat</title>

        <meta
          name="description"
          content=" Kitkat software Technologies is the best social media marketing company in 
Coimbatore. We specialize in Social Media Marketing like Youtube & other promotions. "
        />
      </Helmet>
      <div>
        <div>
          <img
            src={youmain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p class="cont_para_style">
              In the Fast paced digital world relying our business in tradional
              way leads to more cost effective & consume more time to register
              your brand in customers mind.
            </p>
            <div class="img_pro">
              <img
                src={you1}
                width="100%"
                height="100%"
                alt="Data Management"
                style={{ objectFit: "cover" }}
              />
            </div>
            {/* <h2 class="title2">Employee Data Management</h2> */}
            <p className="cont_para_style">
              But Don’t worry about that we have the perfect solution for your
              advertising needs With the focus of your long term business goals
              we strategically align and customize the advertising campaigns to
              keep your business engaged and satisfied.
            </p>
            <p className="cont_para_style">
              over the past 10 years Kitkat software solution provides
              exceptional services for Clients requirements like branding and
              advertising products/services in various digital channels such as
              YouTube, Instagram and more. Our Expert Team will help you to
              improve your brands presence.
            </p>
            <div class="img_pro">
              <img
                src={you2}
                width="100%"
                height="100%"
                alt="Human Resource Management"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YouTube;
