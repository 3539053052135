import React, { useEffect } from "react";
import "./BgAnimation.css";

const BgAnimation = () => {
  useEffect(() => {
    const container = document.querySelector(".animation-container");
    const numBubbles = 50; // Adjust the number of bubbles

    for (let i = 0; i < numBubbles; i++) {
      const bubble = document.createElement("div");
      bubble.className = "bubble";
      bubble.style.left = `${Math.random() * 100}vw`;
      bubble.style.animationDuration = `${3 + Math.random() * 5}s`; // Different speeds
      container.appendChild(bubble);
    }
  }, []);

  return <div className="animation-container"></div>;
};

export default BgAnimation;
