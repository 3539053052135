import React from "react";
import "./AllProduct.css"; // Importing the CSS file
import { productimages } from "./AllProductimg/AllProductImage";

const softwareCategories = [
  "Advocate", "Adsrock", "Apartment", "Bank", "Bet", "Bid", "Billing", "Booking", 
  "Bus Booking", "Business Suite", "Cab", "Card", "Casino", "Chat", "Classifieds", 
  "Clinic", "CMS", "Coin", "Colour Prediction Website", "Coupon", "Courier", "CRM",
  "Digital Product", "Directory", "Doctor Appointment", "Doctor Listing", "Ecommerce",
  "ERP", "Escrow", "Email Marketing", "Exam", "Exchange", "Fans Only", "Food Delivery",
  "Freelancer", "Fund", "Gamers Arena", "Groomify", "Hospital", "Hotel Booking", "HRM",
  "Influencer", "Institute", "Inventory", "Investment", "Invoice", "ISP", "Job",
  "Laboratory", "Lead", "LMS", "Loan", "Lottery", "Matrimony", "Meeting", "Mining", "MLM",
  "News", "NFT", "NGO", "PMS", "POS", "PTC", "QR Code", "QR Menu", "Rate", "Real Estate",
  "Recharge", "Restaurant", "School", "Sender", "Service", "SMM", "SMS", "Social",
  "StocLab", "Store", "Support", "Survey", "Teleman", "Text to Speech", "Tool", "Trade",
  "Travel", "Tailor", "URL Shortener", "Vcard", "Video Streaming", "Video Editing",
  "Gate Pass", "Wallet"
];

const AllProducts = () => {
  return (
    <>
    <div className="fullcontrol">
    <h1 className="headingtag">OUR ALL PRODUCTS</h1>

    <div className="products-container">
      {softwareCategories.map((category, index) => (
        <div key={index} className="product-card">
          <img 
            src={productimages[`img${index + 1}`] || "https://placehold.co/150x150"} 
            alt={category} 
            className="product-image"
          />
          <h3 className="product-title">{category}</h3>
        </div>
      ))}
    </div>
    </div>
    </>
  );
};

export default AllProducts;
