import React from "react";
import "./Erp.css";
import ERP from "../Services/img/erp1.jpg";
import custom_erp_development from "../Products/Products/ERP_1.jpg";
import erp_process from "../Products/Products/ERP_2.jpg";
import { Helmet } from "react-helmet-async";
const ERPPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Enterprise Resource Planning software provider in Coimbatore
        </title>

        <meta
          name="description"
          content=" Empower your business with KitKat Software Technologies excellent ERP 
software service. Streamline process and drive growth."
        />
      </Helmet>

      <div>
        <div>
          <img
            src={ERP}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              Kitkat software technology is the leading ERP (Enterprise Resource
              Planning) software development service provider in Coimbatore,
              with over 10 years of experience. We provide the customized ERP
              software for your business.
            </p>
            <p className="cont_para_style">
              Our ERP software is designed to streamline the process, like
              inventory tracing, payroll, supply chain, finance, and more, with
              the expertise of our experienced ERP software developers.
            </p>
            <div className="img_pro">
              <img
                src={custom_erp_development}
                width="100%"
                height="100%"
                alt="ERP Development"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              If you are ready to optimize and automate your business process,
              KitKat Software's Enterprise Resource Planning Software is the
              best choice. Contact us to get started with the customized ERP
              solution designed to meet your business goals.
            </p>

            <div className="img_pro">
              <img
                src={erp_process}
                width="100%"
                height="100%"
                alt="ERP Software"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ERPPage;
