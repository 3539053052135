import React from "react";
import "./Travel.css";
import Erp_software from "./Products/schoolerp1.jpg";
import travel_business from "./Products/SCHOOL_ERP_1.jpg";
import travel_erp from "./Products/SCHOOL_ERP_2.jpg";

const SchoolERP = () => {
  return (
    <>
      <div>
        <div>
          <img
            src={Erp_software}
            width="100%"
            alt="Travel ERP"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              School ERP from Kit Kat Software Solutions is designed to manage
              all the information, including your academic, financial, staff,
              and student information of the school We provide the customized
              ERP services.
            </p>
            <p className="cont_para_style">
              Kitkat software solution designed this school ERP software in
              concern of bringing all the managerial activities into the single
              platform, such as calculating the attendance and maintaining
              students records.
            </p>
            <div className="side_img1">
              <img
                src={travel_business}
                width="100%"
                height="100%"
                alt="Travel Business Software"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              It is designed and developed to reduce manual work. School ERP
              from KitKat Software Solutions reduces the work duration and
              automates the process. By streamlining the process, such as
              grading and scheduling, the ERP software reduces the burden, and
              the faculties have more time to concentrate on students.
            </p>{" "}
            <p className="cont_para_style">
              If you are looking for your school should be in a top listing ,
              the customized ERP software from us helps you to reduce the work
              burden of your teachers and automate the process easily. And it
              can be the best choice for your school to be in a top listing.
            </p>
            <div className="side_img1">
              <img
                src={travel_erp}
                width="100%"
                height="100%"
                alt="Travel ERP Software"
                style={{ objectFit: "cover" }}
              />
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolERP;
