import React from "react";
import "./Middlebanner.css";
import { motion } from "framer-motion";

const sections = [
  {
    title: "Designing",
    description:
      "Engage with our industry expert team and leverage innovative ideas with the latest technologies and trends. By collaborating with us, we can overcome challenges and turn out a new path.",
    img: "/images/web_designing.jpg",
    reverse: false,
  },
  {
    title: "Planning",
    description:
      "Strategically Plan with our expertise to perfectly align with your needs and engage with us to unlock every possible opportunity for expansion and extend your business market reach to new horizons.",
    img: "/images/front_end.webp",
    reverse: true,
  },
  {
    title: "Developing",
    description:
      "Our industry expert developers are skilled in development to build your websites. They transform your ideas into reality with our latest trending software solutions. We deliver excellent results that exceed your expectations.",
    img: "/images/development.jpg",
    reverse: false,
  },
  {
    title: "Responsive",
    description:
      "Our developers craft flawless solutions accessible through smartphones, laptops, tablets & more, ensuring an exceptional user experience and satisfaction.",
    img: "/images/web_application.jpg",
    reverse: true,
  },
];

const MiddleBanner = () => {
  return (
    <div className="middle-banner-container">
      {sections.map((section, index) => (
        <motion.div
          key={index}
          className={`middle-banner-section ${section.reverse ? "reverse" : ""}`}
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className="middle-banner-text">
            <motion.h2 whileHover={{ scale: 1.1 }} className="banner-heading">
              {section.title}
            </motion.h2>
            <p>{section.description}</p>
          </div>
          <motion.div
            className="middle-banner-image"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5 }}
          >
            <img src={section.img} alt={section.title} />
          </motion.div>
        </motion.div>
      ))}
    </div>
  );
};

export default MiddleBanner;
// import React from "react";
// import { motion } from "framer-motion";
// import "./Middlebanner.css";

// const sections = [
//   {
//     title: "Creative Designing",
//     description:
//       "Transform your ideas into reality with our expert designers. We use cutting-edge tools to craft stunning and innovative UI/UX designs.",
//     imgSrc: "/images/web_designing.jpg",
//     imgAlt: "Web design",
//     reverse: false,
//   },
//   {
//     title: "Strategic Planning",
//     description:
//       "Plan with precision! Our experts guide you through every development phase to ensure your project scales efficiently and effectively.",
//     imgSrc: "/images/front_end.webp",
//     imgAlt: "Front-end development",
//     reverse: true,
//   },
//   {
//     title: "Powerful Development",
//     description:
//       "Leverage the latest tech stack to build high-performance applications. We ensure robust, scalable, and seamless user experiences.",
//     imgSrc: "/images/development.jpg",
//     imgAlt: "Web development",
//     reverse: false,
//   },
//   {
//     title: "Responsive & Adaptive",
//     description:
//       "Your application, anywhere! We create mobile-first, fully responsive designs to provide an optimal user experience across all devices.",
//     imgSrc: "/images/web_designing.jpg",
//     imgAlt: "Mobile Application",
//     reverse: true,
//   },
// ];

// const MiddleBanner = () => {
//   return (
//     <div className="middle-banner">
//       {sections.map((section, index) => (
//         <motion.div
//           className={`banner-section ${section.reverse ? "reverse" : ""}`}
//           key={index}
//           initial={{ opacity: 0, y: 50 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.6, delay: index * 0.2 }}
//           whileHover={{ scale: 1.03 }}
//         >
//           <div className="text-content">
//             <motion.h2
//               initial={{ x: -50, opacity: 0 }}
//               animate={{ x: 0, opacity: 1 }}
//               transition={{ duration: 0.5 }}
//             >
//               {section.title}
//             </motion.h2>
//             <p>{section.description}</p>
//           </div>
//           <div className="image-content">
//             <motion.img
//               src={section.imgSrc}
//               alt={section.imgAlt}
//               whileHover={{ scale: 1.1 }}
//               transition={{ duration: 0.3 }}
//             />
//           </div>
//         </motion.div>
//       ))}
//     </div>
//   );
// };

// export default MiddleBanner;
