import React from "react";
import "./TieCompani.css";
import tieImages from "./TieImage"; // Importing images from a separate file

const TieCompani = () => {
  return (
    <div className="tie-container">
      <h1 className="tie-heading">Our Tie-up Companies</h1>
      <div className="tie-grid">
        {tieImages.map((img, index) => (
          <div key={index} className="tie-card">
            <img src={img} alt={`Company ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TieCompani;
