const ServiceItems = 
    [
      { 
        id:22,
        title: 'Website Design And Development',
        path: '/WebsiteDesignPage',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      { 
        id:22,
        title: 'Web Application Development',
        path: '/Webappicationdev',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      {
        id:23,
        title: 'Customize Apllication Development',
        path: '/WebHostingPage',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      
      
      {
        id:24,
        title: 'Mobile Application Development',
        path: '/AndroidApplicationPage',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      {
        id:26,
        title: 'Bulk SMS',
        path: '/BulkSMSPage',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
     
      
     
      {
        id:25,
        title: 'Digital Marketing',
        path: '/Digital',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      
     
      {
        id:29,
        title: 'Data Analyst',
        path: '/dataanalyst',
        cName: 'drop_down_cont2',
        idName:'drops2'
      },
      {
        id:29,
        title: 'SEO',
        path: '/seo',
        cName: 'drop_down_cont2',
        idName:'drops2'
      }, {
        id:29,
        title: 'Youtube & Insta Ads',
        path: '/youtube&insta',
        cName: 'drop_down_cont2',
        idName:'drops2'
      }
    ]
  
  export  default ServiceItems;
      