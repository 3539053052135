import React from "react";
import "./Exitknown.css";
import { Link } from "react-router-dom";
import knownimage1 from "../Sliderimg/side1.avif";
import knownimage2 from "../Sliderimg/side3.avif";

const KnownUs = () => {
  return (
    <>
      <div className="cont">
        <div className="about_index">
          <div className="about_background_image"></div>
          <div className="about_index_para">
            <h2 class="t1">
              <p class="pre" style={{ marginBottom: 20 }}>
                Know About
              </p>{" "}
              <strong style={{ color: "#FF635E", margin: "0" }}>
                Kitkat Software Technologies
              </strong>
            </h2>
            <p class="p1" style={{ marginTop: 20 }}>
              At KitKat Software Technologies, we deliver high-quality digital
              solutions tailored to meet the unique needs of businesses across
              various industries. Our expertise spans Web Development,
              Application Development, Creative Design, Quality Assurance,
              Banking Services, and Billing Software.
            </p>
            <p class="p1">
              We take time to understand each client’s specific needs, ensuring
              we provide solutions that help them achieve their goals. Our team
              specializes in building custom websites that align with business
              objectives, offering seamless functionality and a user-friendly
              experience. We also provide ongoing maintenance services to ensure
              your website remains updated and performs at its best.
            </p>
            <p class="p1">
              {" "}
              With a customer-focused approach and a passion for innovation,
              KitKat Software Technologies is committed to delivering reliable,
              efficient, and future-ready solutions for businesses of all sizes.
            </p>
            <p class="p1_d" style={{ marginBottom: 30 }}>
              Your website is often the first interaction potential customers
              have with your business. That's why we focus on designing websites
              that are informative, visually appealing, and easy to navigate. A
              professionally designed website not only strengthens your brand’s
              credibility but also ensures a seamless user experience that
              converts visitors into customers.
            </p>
            <p class="p1_d" style={{ marginBottom: 30 }}>
              Selecting the right technology is critical for long-term success.
              Whether you need to optimize your existing system or build
              something new, our experts offer effective solutions that align
              with your business strategy.
            </p>{" "}
            <p class="p1_d" style={{ marginBottom: 30 }}>
              We believe in involving our clients at every stage of the
              development process. By understanding your business goals, we
              ensure that the final product is in line with your expectations.
              We adopt the best development methodologies such as Agile to
              ensure flexibility, collaboration, and continuous improvement.
            </p>
            <Link class="rm" to="./AboutPage">
              Read More
            </Link>
          </div>

          <div class="about_index_image">
            <img src={knownimage1} alt="IOS Application" />
            <img src={knownimage2} alt="Windows Application" />
          </div>
        </div>
      </div>
    </>
  );
};
export default KnownUs;
