import React from "react";
import "./Webdesign.css";
import digimain from "./Services/digital marketing.jpg";
import digi1 from "./Services/DIGITAL MARKETING_1.jpg";
import digi2 from "./Services/DIGITAL MARKETING_2.jpg";
import { Helmet } from "react-helmet-async";

const Digitalpage = () => {
  return (
    <>
       <Helmet>
            <title>Digital marketing training in Coimbatore|software solutions</title>
            <meta
              name="description"
              content="  Kitkat software Technologies is the Best Digital Marketing Company in 
Coimbatore.We are providing the Best Digital Marketing services and training at affordable"
            />
            <meta
              name="keywords"
              content="digital marketing training in Coimbatore"
            />
          </Helmet>
      <div>
        <div>
          <img
            src={digimain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              At kitkat software Technologies over the past 10 years we are
              providing excellent online business growth services to our clients
              in various Business category. We specialize in enhancing your
              online presences through various online channels like Instagram,
              Facebook,
            </p>

            <div class="img_pro">
              <img
                src={digi1}
                width="100%"
                height="100%"
                alt="Static Website"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              Our experienced and expert team is dedicating to bring you the
              quality leads through promoting your business in the digital world
              through targeted digital platforms. Reach out to us and ensure
              your success in the fast growing digital world.
            </p>
            <div className="img_pro">
              <img
                src={digi2}
                width="100%"
                height="100%"
                alt="Dynmaic Website"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Digitalpage;
