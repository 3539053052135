import React from "react";
import "./Hrm.css";
import HRM_service from "./Services/data analytics.jpg";
import Data_management from "./Services/DATA ANALYST_1.jpg";
import Human_Resource_Management from "./Services/DATA ANALYST_2.jpg";
import { Helmet } from "react-helmet-async";
const DataAnalyst = () => {
  return (
    <>
     <Helmet>
                <title> Best Data analytics service providers in Coimbatore| Kitkat</title>
                <meta
                  name="description"
                  content=" Get deep insights with our data analytics services. We are providing Data analytic 
service & Data Analyst Course with placement in Coimbatore over past 10 years"
                />
                <meta
                  name="keywords"
                  content="Data Analyst Course with placement in Coimbatore"
                />
              </Helmet>
      <div>
        <div>
          <img
            src={HRM_service}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p class="cont_para_style">
              At kitkat software solutions we provide the top notch digital
              services development services and Data analytics services for your
              business.
            </p>
            <div class="img_pro">
              <img
                src={Data_management}
                width="100%"
                height="100%"
                alt="Data Management"
                style={{ objectFit: "cover" }}
              />
            </div>
            {/* <h2 class="title2">Employee Data Management</h2> */}
            <p className="cont_para_style">
              our services in data analytics for your business includes Data
              Collection & Cleaning, Data Visualization & Reporting, Business
              Intelligence & Strategy, Predictive Analytics & Forecasting,
              Market Research & Competitor Analysis
            </p>
            <p className="cont_para_style">
              we are specialized in offering data analytics services to the
              industries such as marketing, Finance, Health care, Supply chain
              and logistics. our premium service provides you the best results
              for your business through our expert market researchers
              outstanding strategies.
            </p>
            <div class="img_pro">
              <img
                src={Human_Resource_Management}
                width="100%"
                height="100%"
                alt="Human Resource Management"
                style={{ objectFit: "cover" }}
              />
            </div>
       
          </div>
        </div>
      </div>
    </>
  );
};
export default DataAnalyst;
