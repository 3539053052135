import React from "react";
import "./Hrm.css";
import seomain from "./Services/SEO_Main.jpg";
import seo1 from "./Services/SEO_1.jpg";
import seo2 from "./Services/SEO_3.jpg";
import { Helmet } from "react-helmet-async";
const Seo = () => {
  return (
    <>
         <Helmet>
              <title> Best SEO service Provider in Coimbatore|Kitkat Software</title>
      
              <meta
                name="description"
                content=" At kitkat software Technologies we are offering top notch SEO Services & 
Software Training Courses in Coimbatore with Certifications at best price."
              />
              
            </Helmet>
      <div>
        <div>
          <img
            src={seomain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p class="cont_para_style">
              Kitkat Software Technology is a leading service provider for
              digital business growth. We specialize in digital marketing and
              software development, making us one of the top service providers
              in Coimbatore.
            </p>
            <div class="img_pro">
              <img
                src={seo1}
                width="100%"
                height="100%"
                alt="Data Management"
                style={{ objectFit: "cover" }}
              />
            </div>
            {/* <h2 class="title2">Employee Data Management</h2> */}
            <p className="cont_para_style">
              With over 8 years of experience, our expert team has achieved
              remarkable success in delivering software solutions, including
              online branding, to help businesses grow effectively.
            </p>
            <p className="cont_para_style">
              Our experienced and skilled team is here to boost your websites
              with a better ranking and tracking system. Don’t wait; the digital
              system moves fast.
            </p>
            <div class="img_pro">
              <img
                src={seo2}
                width="100%"
                height="100%"
                alt="Human Resource Management"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Seo;
