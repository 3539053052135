import React from "react";
import "./Webdesign.css";
import webmain from "./Services/web application development.jpg";
import web1 from "./Services/WEB APPLICATION DEVELOPMENT_1.jpg";
import web2 from "./Services/WEB APPLICATION DEVELOPMENT_2.jpg";
import { Helmet } from "react-helmet-async";

const Webapplication = () => {
  return (
    <>
      <Helmet>
        <title>
          Best Web Development Company| Kitkat Software Technologies
        </title>
        <meta
          name="description"
          content="Kitkat software Technology is the best web development company in Coimbatore. 
We provide attractive, responsive websites at affordable cost."
        />
        <meta
          name="keywords"
          content=" Best Web Development Company"
        />
      </Helmet>
      <div>
        <div>
          <img
            src={webmain}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              As a leading web application development service provider in
              Coimbatore, Kitkat Software Solutions plays a vital role.
            </p>

            <div class="img_pro">
              <img
                src={web1}
                width="100%"
                height="100%"
                alt="Static Website"
                style={{ objectFit: "cover" }}
              />
            </div>
            <p className="cont_para_style">
              We have been in this field for over 8 years, and our mission is to
              provide cost-effective and quality services to our clients. Our
              web application services start from designing and creating
              websites as per the client's business needs to launching the
              website. .{" "}
            </p>

            <p className="cont_para_style">
              If you are looking for the best web application development
              service providers around you, feel free to contact us
            </p>
            <div className="img_pro">
              <img
                src={web2}
                width="100%"
                height="100%"
                alt="Dynmaic Website"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Webapplication;
