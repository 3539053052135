import tieimg1 from "./tie images/tie1.jpg";
import tieimg2 from "./tie images/tie2.png";
import tieimg3 from "./tie images/tie3.jpg";
import tieimg4 from "./tie images/tie4.jpg";
import tieimg5 from "./tie images/tie5.jpg";
import tieimg6 from "./tie images/tie6.jpg";
import tieimg7 from "./tie images/tie7.png";
import tieimg8 from "./tie images/tie8.png";
import tieimg9 from "./tie images/tie9.webp";
import tieimg10 from "./tie images/tie10.jpg";
import tieimg11 from "./tie images/tie11.png";
import tieimg12 from "./tie images/tie12.jpg";
import tieimg13 from "./tie images/tie13.jpg";
import tieimg14 from "./tie images/tie14.svg";
import tieimg15 from "./tie images/tie15.jpg";
import tieimg16 from "./tie images/tie16.jpeg";
import tieimg17 from "./tie images/tie17.jpeg";
import tieimg18 from "./tie images/tie18.jpeg";
import tieimg19 from "./tie images/tie19.png";
import tieimg20 from "./tie images/tie20.jpeg";
import tieimg21 from "./tie images/tie21.jpeg";
import tieimg22 from "./tie images/tie22.jpeg";
import tieimg23 from "./tie images/tie23.jpeg";
import tieimg24 from "./tie images/tie24.png";
import tieimg25 from "./tie images/tie25.png";

export default [
  tieimg1, tieimg2, tieimg3, tieimg4, tieimg5, tieimg6, tieimg7, tieimg8,
  tieimg9, tieimg10, tieimg11, tieimg12, tieimg13, tieimg14, tieimg15, tieimg16,
  tieimg17, tieimg18, tieimg19, tieimg20, tieimg21, tieimg22, tieimg23, tieimg24, tieimg25
];
