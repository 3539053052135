import React from "react";
import { Helmet } from "react-helmet-async";
import Slider from "../../Slider/Slider";
import MiddleBanner from "../../Middlebanner/Middlebanner";
import CourseContent from "../../Course/Course";
import Flip from "../../Flip/Flip";
import KnownUs from "../../Known/Exitknown";
import "./Home.css";
import Mid from "./mid";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Top Web Development Company in Coimbatore | Kitkat Software</title>
        <meta name="description" content="Kitkat Software Technologies is a top web design & development company in Coimbatore, offering professional web design, SEO, mobile app & software development." />
        <meta name="keywords" content="Best software development company in Coimbatore, No.1 Training Institute in Coimbatore, Software Training Courses in Coimbatore with Certifications" />
      </Helmet>

      <div className="commonHome">
        <Slider />
        <KnownUs />
        <MiddleBanner />
        <Mid />
        <CourseContent />
        <Flip />
      </div>
    </>
  );
};

export default Home;
