import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Dateyear from "../Yearcontent/Yearcontent";
const FooterPage = () => {
  return (
    <>
      <div className="footer">
        <div className="footer_flex">
          <div className="products footer_flex_cont">
            <h2>Products</h2>
            <ul style={{ paddingLeft: 0 }}>
              <li>
                <Link to="/E-CommercePage" title="digital Commerce">
                E-commerce WebSite
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/CRMPage" title="Office ERM">
                CRM

                </Link>
              </li>
              <li>
                {" "}
                <Link to="/ERPPage" title="ERPpage">
                ERP

                </Link>
              </li>
              <li>
                {" "}
                <Link to="/BiometricPage" title="BiometricPage">
                  BioMetric Based HRM
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/SchoolErp" title="Mini Banking">
                School ERP
                </Link>
              </li>
              <li>
                <Link to="/GoldLoanPage" title="Gold Loan Management">
                  Gold Loan Management
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/matrimonial" title="Medical Billing">
                 Matrimonial Website
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/MedicalPage" title="Medical Billing">
                  Medical Billing
                </Link>
              </li>
              {/* <li>
                {" "}
                <Link to="/TravelERPPage" title="Travel ERP">
                  Travel ERP
                </Link>
              </li> */}
              <li>
                {" "}
                <Link to="/MiniBankingPage" title="pos_billing">
                Mini Banking

                </Link>
              </li>
            </ul>
          </div>
          <div className="services footer_flex_cont">
            <h2>Services</h2>
            <ul style={{ paddingLeft: 0 }}>
              <li>
                <Link
                  to="/WebsiteDesignPage"
                  title="Website Design and Development"
                >
                  Website Design and Development
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Webappicationdev" title="Customized web">
                Web Application Development
                </Link>
              </li>
              
              <li>
                {" "}
                <Link to="/WebHostingPage" title="Web hosting">
                  Web Hosting
                </Link>
              </li>
              <li>
                <Link to="/AndroidApplicationPage" title="Android">
                  Mobile Application Development
                </Link>
              </li>
              {/* <li>
                <Link to="/IOSPage" title="ios">
                  IOS Application Development
                </Link> */}
              {/* </li> */}
              <li>
                <Link to="/BulkSMSPage" title="Bulk SMS">
                  Bulk SMS
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/Digital" title="HRM">
                  Digital Marketing
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/dataanalyst" title="HRM">
                Data Analyst
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/seo" title="ERP">
                  SEO
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/youtube&insta" title="CRM">
                  Youtube & Insta Ads
                </Link>
              </li>
            </ul>
          </div>
          <div className="contact footer_flex_cont">
            <h2>Contacts</h2>
            <ul style={{ paddingLeft: 0 }}>
              <li>No.70/77,1st floor, Krishna complex,</li>
              <li>Kattor Road,Papanaickenpalayam, </li>
              <li>Coimbatore-641037, </li>
              <li>Tamil Nadu</li>
              <li>
                Phone :<span style={{ color: "#ffffff" }}> 0422 495 7272</span>
              </li>
              <li>
                Mobile :<span style={{ color: "#ffffff" }}> 7010816299</span>
              </li>
              <li style={{ color: "#ffffff" }}>
                kitkatsoftwaretechnologies@gmail.com
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_line"></div>
        <div className="footer_copy">
          <Dateyear />
        </div>
      </div>
    </>
  );
};
export default FooterPage;
