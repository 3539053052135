import React from "react";
import "./Android.css";
import android_application from "./Services/mobile application development.jpg";
import android_development from "./Services/MOBILE APPLICATION DEVELOPMENT_1.jpg";
import Android_apps from "./Services/MOBILE APPLICATION DEVELOPMENT_2.jpg";
import { Helmet } from "react-helmet-async";
const AndroidPage = () => {
  return (
    <>
     <Helmet>
        <title> Web development company in Coimbatore| Kitkat Software</title>
        <meta
          name="description"
          content=" Transform Your Business with Powerful Mobile Apps. Your trusted partner for 
high performance,Customizable mobile app software development  service in Coimbatore"
        />
       
      </Helmet>

      <div>
        <div>
          <img
            src={android_application}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
        
            <p className="cont_para_style">
              Mobile applications are software designed to run on mobile phones
              such as smartphones, tablets, and more. Mobile application
              developers at KitKat Software Technologies specialize in creating
              high-quality and user-friendly mobile applications.{" "}
            </p>
            <div class="img_pro">
              <img
                src={android_development}
                width="100%"
                height="100%"
                alt="Android Development"
                style={{ objectFit: "cover" }}
              />
            </div>
            {/* <p class="cont_para_style">To create the most competitive Android applications, we employ cutting-edge methods and 
           world-class technologies. We enjoy stepping outside of our comfort zone to develop applications that meet our customers' needs.</p> */}

            <p className="cont_para_style">
              {" "}
              We Customize application with highly equipped technologies and
              trends, to achieve your business objectives. Our developers are
              well-versed in creating and developing applications such as
              e-commerce, delivery services, and ticket and ticket booking app
              platforms.{" "}
            </p>

            <p className="cont_para_style">
              {" "}
              Our goal is to provide high-quality services at affordable cost to
              our clients to drive achievements for their business through
              customized software solutions like software development, mobile
              application development, and other tailored solutions
            </p>
            <div className="img_pro">
              <img
                src={Android_apps}
                width="100%"
                height="100%"
                alt="Android Apps"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AndroidPage;
