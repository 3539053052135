import React, { useContext } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Training_details } from "./Training_details/Training_datas";
import Skills from "./Skills/Skills";
import { contextData } from "../../App";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./TrainingPage.css";
import TieCompani from "./TieCompani/TieCompani";

export default function TrainingPage() {
  const { blur, handle, adbanner, closeBanner } = useContext(contextData);
  const navigate = useNavigate();

  return (
    <>
      <div className="training-container">
        {/* Header Section */}
        <div className="hero-section">
          <div className="hero-content">
            <h1>At Kitkat Solution, our success is intertwined with the success of our clients</h1>
            <p>
              We take pride in being a leading software training institute that
              has empowered individuals and organizations to thrive in the
              dynamic world of technology. Our client partnerships are a
              testament to the quality of education and the real-world skills we
              impart to our students.
            </p>
            <button className="enquiry-btn" onClick={() => navigate("/mail")}>
              Enquiry Now <FaArrowRightLong />
            </button>
          </div>
          <div className="hero-image">
            <img src={require("./img/banar.png")} alt="Kitkat Academy" />
          </div>
        </div>

        {/* Courses Section */}
        <div className="courses-section">
          <h2>Our Courses</h2>
          <p className="course-description">
            Learn new skills, pursue your interests or advance your career with
            our distinctive course catalogues. Our updated syllabus covers all industry
            standards.
          </p>
          <Container>
            <div className="course-grid">
              {Training_details.map((course) => (
                <div key={course.id} className="course-box" onClick={() => {handle(course, course.sub); navigate("/Status")}}>
                  <div className="card">
                    <div className="card-image">
                      <img src={course.img} alt={course.title} />
                    </div>
                    <h1>{course.title}</h1>
                    <button className="view-btn" >
                      View More <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div>
               <TieCompani/>
            </div>
          </Container>
        </div>
        
      </div>

      {/* Full-Screen Ad */}
      {/* <div ref={adbanner} className="full-screen-ad" id="adBanner">
        <Skills />
        <span className="close-btn" onClick={closeBanner}>✕</span>
      </div> */}
    </>
  );
}
