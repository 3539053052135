import React from "react";
import "./Crm.css";
import CRM_software from "../Products/Products/crm1.webp";
import custom_crm from "../Products/Products/CRM_1.jpg";
import CRM_development from "../Products/Products/CRM_2.jpg";
import customer_relationship from "../Services/img/customer_relationship.webp";
import { Helmet } from "react-helmet-async";
const CRMPage = () => {
  return (
    <>
     <Helmet>
          <title>Customer Relationship Management software provider |Coimbatore </title>
  
          <meta
            name="description"
            content="The best CRM software development company in Coimbatore offers custom CRM 
solutions to help businesses manage customer relationships efficiently"
          />
          
        </Helmet>
      <div>
        <div>
          <img
            src={CRM_software}
            width="100%"
            alt="Biometric_hrm"
            style={{
              objectFit: "cover",
              marginTop: "80px",
              maxHeight: "400px",
            }}
          />
        </div>
      </div>

      <div className="content_lists">
        <div className="product_cont">
          <div className="career_p">
            <p className="cont_para_style">
              CRM Customer Relationship Management software From KitKat,
              software technologies are designed to maintain strong customer
              relationships. Over the 10 years of experience as a service
              provider, we offer excellent solutions to businesses to analyse
              and manage customer interactions successfully.
            </p>
            <div className="img_pro">
              <img
                src={custom_crm}
                width="100%"
                height="100%"
                alt="Custom CRM"
                style={{ objectFit: "cover" }}
              />
            </div>

            <p className="cont_para_style">
              We offer the best Customer Relationship Management software in
              Coimbatore. Our software provides you with personalized
              interaction, proactive engagement,, and effective resolution of
              the issue. With the real-time insights and analytics,, our CRM
              software solution builds strong customer relationship and boost
              your business growth.
            </p>
            <div className="img_pro">
              <img
                src={CRM_development}
                width="100%"
                height="100%"
                alt="CRM Development"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CRMPage;
