// import React, { useContext } from 'react';
// import './Skills.css';
// import { contextData } from '../../../App';
// import BgAnimation from '../Skills-Page-Animation/BgAnimation';
// import { useNavigate } from 'react-router-dom';

// function Skills ()  {

//   const contextvalue = useContext(contextData)
//    let { tools  ,subject ,mailsend }=contextvalue
//    const navigate = useNavigate()
//   //  console.log(tools.tools);
//    let tools_kit = tools.tools
    
 


//   return (
//     <>
//      <BgAnimation/>
//     <div className="skills-container">
//       <h2>Skills You'll Learn</h2>
//       {
        
//             <p className='subject'>{subject}</p>
        
//       }
//       <div className="skills-section">
//         {tools_kit&&tools_kit?.filter((v)=>{return v.front_end_tool && v.front_end_tool}).map((v)=>{
//           console.log(v);
//           let {front_end_tool } = v
          
//           return(
//             <>
//             <div className="skills-category">
//           <h3>{front_end_tool.front_end_name}</h3>
//           <div className="skills-list">
//             <div className={front_end_tool.front_tool1_class}>
//               <img src={front_end_tool.front_tool1} alt="" />
//               <p>{front_end_tool.front_tool1_name}</p>
//             </div>
//             <div className={front_end_tool.front_tool2_class}>
//               <img src={front_end_tool.front_tool2} alt="" />
//               <p>{front_end_tool.front_tool2_name}</p>
//             </div>
//             <div className={front_end_tool.front_tool3_class}>
//               <img src={front_end_tool.front_tool3} alt="" />
//               <p>{front_end_tool.front_tool3_name}</p>
//             </div>
//             <div className={front_end_tool.front_tool4_class}>
//               <img src={front_end_tool.front_tool4} alt="" />
//               <p>{front_end_tool.front_tool4_name}</p>
//             </div>
//             <div className={front_end_tool.front_tool5_class}>
//               <img src={front_end_tool.front_tool5} alt="" />
//               <p>{front_end_tool.front_tool5_name}</p>
//             </div> 
//             <div className={front_end_tool.front_tool6_class}>
//               <img src={front_end_tool.front_tool6} alt="" />
//               <p>{front_end_tool.front_tool6_name}</p>
//             </div>
//           </div>
//         </div>
        
            
//             </>
//           )
//         })}
//         {
//           tools_kit&&tools_kit?.filter((v)=>{return v.back_end_tool && v.back_end_tool}).map((v)=>{ 
//             let {back_end_tool} = v

//             return(
//               <>
//               <div className="skills-category">
//           <h3>{back_end_tool.back_end_name}</h3>
//           <div className="skills-list">
//             <div className={back_end_tool.back_tool1_class}>
//               <img src={back_end_tool.back_tool1} alt="" />
//               <p>{back_end_tool.back_tool1_name}</p>
//             </div>
//             <div className={back_end_tool.back_tool2_class}>
//               <img src={back_end_tool.back_tool2} alt="" />
//               <p>{back_end_tool.back_tool2_name}</p>
//             </div>
//             <div className={back_end_tool.back_tool3_class}>
//               <img src={back_end_tool.back_tool3} alt="" />
//               <p>{back_end_tool.back_tool3_name}</p>
//             </div>
//             <div className={back_end_tool.back_tool4_class}>
//               <img src={back_end_tool.back_tool4} alt="" />
//               <p>{back_end_tool.back_tool4_name}</p>
//             </div>
//             <div className={back_end_tool.back_tool5_class}>
//               <img src={back_end_tool.back_tool5} alt="" />
//               <p>{back_end_tool.back_tool5_name}</p>
//             </div>
//             <div className={back_end_tool.back_tool6_class}>
//               <img src={back_end_tool.back_tool6} alt="" />
//               <p>{back_end_tool.back_tool6_name}</p>
//             </div>
//           </div>
//         </div>
              
//               </>
//             )
//           })
//         }
//         {
//           tools_kit&&tools_kit?.filter((v)=>{return v.database && v.database}).map((v)=>{ 
//             let {database} = v

//             return(
//               <>
//               <div className="skills-category">
//           <h3>{database.data_base_name}</h3>
//           <div className="skills-list">
//             <div className="skill-item">
//               <img src={database.data_tool1} alt="Core Python" />
//               <p>{database.data_tool1_name}</p>
//             </div>
//             <div className="skill-item">
//               <img src={database.data_tool2} alt="Core Python" />
//               <p>{database.data_tool2_name}</p>
//             </div>
           
            
            
          
//           </div>
//         </div>
              
//               </>
//             )
//           })
//         }
//         {
//           tools_kit&&tools_kit?.filter((v)=>{return v.database && v.database}).map((v)=>{ 
//             let {tools} = v

//             return(
//               <>
//               <div className="skills-category">
//           <h3>{tools.tool_name}</h3>
//           <div className="skills-list">
//             <div className="skill-item">
//               <img src={tools.tool1} alt="Core Python" />
//               <p>{tools.tool1_name}</p>
//             </div>
//             <div className="skill-item">
//               <img src={tools.tool2} alt="Core Python" />
//               <p>{tools.tool2_name}</p>
//             </div>
           
            
            
          
//           </div>
//         </div>

       
              
//               </>
//             )
//           })
//         }
       
      
//           </div> 
//           <div className='btns'>
          
          


// {/* 
// <button class="button2" type="button">
//   <span class="button__text2">{tools.pdf.pdfname}</span>
//   <span class="button__icon2"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307" data-name="Layer 2" class="svg"><path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
// </button>  */}
      
//       <button className='register' onClick={()=>{navigate("/Mail")}} >Register Here</button>
//        {/* onSubmit={mailsend(tools.title)} */}
         
//          </div>
//     </div>

//     </>
//   );
// };

// export default Skills;
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { contextData } from "../../../App";
import BgAnimation from "../Skills-Page-Animation/BgAnimation";
import "./Skills.css";

const Skills = () => {
  const contextValue = useContext(contextData);
  const { tools, subject } = contextValue;
  const navigate = useNavigate();
  const toolsKit = tools?.tools || [];

  return (
    <div className="skills-container">
      <BgAnimation />
      
      <div className="skills-content">
        <h2 className="section-title">Skills You'll Learn</h2>
        <p className="subject-text">{subject}</p>
        
        <div className="skills-grid">
          {toolsKit.map((v, index) =>
            v.front_end_tool ? (
              <div key={index} className="skill-card">
                <h3 className="skill-category">{v.front_end_tool.front_end_name}</h3>
                <div className="skill-icons">
                  {[...Array(6)].map(
                    (_, i) =>
                      v.front_end_tool[`front_tool${i + 1}`] && (
                        <div key={i} className="skill-item">
                          <img
                            src={v.front_end_tool[`front_tool${i + 1}`]}
                            alt=""
                            className="skill-icon"
                          />
                          <p>{v.front_end_tool[`front_tool${i + 1}_name`]}</p>
                        </div>
                      )
                  )}
                </div>
              </div>
            ) : null
          )}

          {toolsKit.map((v, index) =>
            v.back_end_tool ? (
              <div key={index} className="skill-card">
                <h3 className="skill-category">{v.back_end_tool.back_end_name}</h3>
                <div className="skill-icons">
                  {[...Array(6)].map(
                    (_, i) =>
                      v.back_end_tool[`back_tool${i + 1}`] && (
                        <div key={i} className="skill-item">
                          <img
                            src={v.back_end_tool[`back_tool${i + 1}`]}
                            alt=""
                            className="skill-icon"
                          />
                          <p>{v.back_end_tool[`back_tool${i + 1}_name`]}</p>
                        </div>
                      )
                  )}
                </div>
              </div>
            ) : null
          )}

          {toolsKit.map((v, index) =>
            v.database ? (
              <div key={index} className="skill-card">
                <h3 className="skill-category">{v.database.data_base_name}</h3>
                <div className="skill-icons">
                  {[...Array(2)].map(
                    (_, i) =>
                      v.database[`data_tool${i + 1}`] && (
                        <div key={i} className="skill-item">
                          <img
                            src={v.database[`data_tool${i + 1}`]}
                            alt=""
                            className="skill-icon"
                          />
                          <p>{v.database[`data_tool${i + 1}_name`]}</p>
                        </div>
                      )
                  )}
                </div>
              </div>
            ) : null
          )}
        </div>

        <div className="register-btn-container">
          <button className="register-btn" onClick={() => navigate("/Mail")}>
            Register Here
          </button>
        </div>
      </div>
    </div>
  );
};

export default Skills;